import React, { useEffect } from 'react';
import { Column, Row, Tooltip, Text } from '@components-teammove';
import { DetalheText, FillView, IconWarning, MainTicket, RowSpaceBetween, StyleModal, TableStyle, TextStyle } from './styles';
import PrazoStatusItem from './PrazoStatusItem';
import StatusItem from './StatusItem';
import { STATUS } from './rules';
import { changeVisibleBriefingReplyEtapas } from '@ducks/chamadosHistorico';
import { useDispatch, useSelector } from 'react-redux';
import { InfoUsuario, downloadBlobAsFile } from '@utils';
import { getPrintoutOfSteps, resetBlob } from '@ducks/chamadosEtapas';

export default function ModalViewSteps({ mainTicket, steps, loadingSteps, visible, setVisibleViewStep, onCancel, setCurrentStep }) {
  const dispatch = useDispatch();

  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));

  const successBlob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('successBlob'));
  const loadingBlob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('loadingBlob'));
  const blob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('blob'));

  const handleViewTicket = (step) => {
    if (step.status === STATUS.AGUARDANDO_BRIEFING && step.idUsuarioCriacao === InfoUsuario.get('cdUsuario')) {
      setCurrentStep(step);
      setVisibleViewStep(false);
      dispatch(changeVisibleBriefingReplyEtapas(true));
    } else {
      window.open(`/chamados/tickets/${step.id}`);
    }
  };

  useEffect(() => {
    if (mainTicket?.id && visible) dispatch(getPrintoutOfSteps(mainTicket.id));

    return () => dispatch(resetBlob());

  }, [mainTicket, visible]);

  const handlePrinting = () => {
    if (successBlob) {
      downloadBlobAsFile(blob, `${mainTicket.protocolo}.pdf`);
    } else {
      dispatch(getPrintoutOfSteps(mainTicket.id));
    }
  };

  useEffect(() => {
    dispatch(changeVisibleBriefingReplyEtapas(false));
  }, [successFinishBriefingReply]);

  const columns = [
    {
      title: 'Etapas',
      dataIndex: 'step',
      render: (text, step) => (
        <Column>
          <TextStyle font='14px' weight='400' $opacity>{step.protocolo}</TextStyle>
          <Tooltip title={step.assunto}>
            <TextStyle maxWidth='320px' font='18px' weight='700'>{step.assunto}</TextStyle>
          </Tooltip>
          <TextStyle font='12px' weight='400' $opacity>{step.nmCategoriaCompleto}</TextStyle>
        </Column>
      )
    },
    {
      title: 'Prazo',
      dataIndex: 'prazo',
      render: (text, step) => (
        <FillView>
          {!step.aguardandoResposta && <PrazoStatusItem chamado={step} />}
          {(step.nmUsuarioAtendimento) && <DetalheText>Resp:{step?.nmUsuarioAtendimento ? step?.nmUsuarioAtendimento : <IconWarning />}</DetalheText>}
        </FillView>
      )
    },
    {
      title: 'Status',
      dataIndex: 'statusDs',
      width: '165px',
      render: (text, step) => {
        return (
          <RowSpaceBetween>
            <StatusItem texto={text} chamado={step} />
          </RowSpaceBetween>
        );
      }
    },
  ];

  return (
    <StyleModal
      visible={visible}
      onCancel={onCancel}
      width={885}
      title='Visualização de etapas'
      cancelText='Fechar'
      onOk={handlePrinting}
      okButtonProps={{ loading: loadingBlob }}
      okText='Imprimir'
    >
      <Column gap='32px'>
        <Column gap='12px'>
          <Row padding='0 0 0 24px'>
            <Text size='16px' weight='700'>Ticket Origem</Text>
          </Row>
          <MainTicket>
            <Column width='40%'>
              <TextStyle font='14px' weight='400' $opacity>{mainTicket?.protocolo}</TextStyle>
              <Tooltip title={mainTicket.assunto}>
                <TextStyle maxWidth='320px' font='18px' weight='700'>{mainTicket?.assunto}</TextStyle>
              </Tooltip>
              <TextStyle font='12px' weight='400' $opacity>{mainTicket?.nmCategoriaCompleto}</TextStyle>
            </Column>
            <Column justify='center'>
              <TextStyle font='18px' weight='400'>{mainTicket?.nmUnidade}</TextStyle>
            </Column>
            <Row gap='48px' align='center'>
              <Column>
                <FillView>
                  {!mainTicket.aguardandoResposta && <PrazoStatusItem chamado={mainTicket} />}
                  {(mainTicket.nmUsuarioAtendimento) && <TextStyle font='12px' weight='400' $opacity>Resp:{mainTicket?.nmUsuarioAtendimento ? mainTicket?.nmUsuarioAtendimento : <IconWarning />}</TextStyle>}
                </FillView>
              </Column>
              <RowSpaceBetween>
                <StatusItem texto={mainTicket.statusDs} chamado={mainTicket} />
              </RowSpaceBetween>
            </Row>
          </MainTicket>
        </Column>
        <TableStyle
          columns={columns}
          dataSource={steps}
          loading={loadingSteps}
          onRow={(step) => ({ onClick: () => handleViewTicket(step) })}
          hidePagination
        />
      </Column>
    </StyleModal>
  );
}
