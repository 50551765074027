import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification, translateErrors } from '@utils';
import { 
  getVoipConnection as getVoipConnectionSdk, 
  getVoipRecordDetails as getVoipRecordDetailsSdk,
  postLigar as postLigarSdk,
  postFinishActivity as postFinishActivitySdk,
  postSaveCall as postSaveCallSdk,
} from '@sdk/Voip';

//Action Types
export const Types = {
  GET_VOIP_CONNECTION: 'VOIP/GET_VOIP_CONNECTION',
  GET_VOIP_CONNECTION_SUCCESS: 'VOIP/GET_VOIP_CONNECTION_SUCCESS',
  GET_VOIP_CONNECTION_ERROR: 'VOIP/GET_VOIP_CONNECTION_ERROR',

  GET_VOIP_RECORD_DETAILS: 'VOIP/GET_VOIP_RECORD_DETAILS',
  GET_VOIP_RECORD_DETAILS_SUCCESS: 'VOIP/GET_VOIP_RECORD_DETAILS_SUCCESS',
  GET_VOIP_RECORD_DETAILS_ERROR: 'VOIP/GET_VOIP_RECORD_DETAILS_ERROR',

  POST_LIGAR: 'VOIP/POST_LIGAR',
  POST_LIGAR_SUCCESS: 'VOIP/POST_LIGAR_SUCCESS',
  POST_LIGAR_ERROR: 'VOIP/POST_LIGAR_ERROR',

  POST_FINISH_ACTIVITY: 'VOIP/POST_FINISH_ACTIVITY',
  POST_FINISH_ACTIVITY_SUCCESS: 'VOIP/POST_FINISH_ACTIVITY_SUCCESS',
  POST_FINISH_ACTIVITY_ERROR: 'VOIP/POST_FINISH_ACTIVITY_ERROR',

  POST_SAVE_CALL: 'VOIP/POST_SAVE_CALL',
  POST_SAVE_CALL_SUCCESS: 'VOIP/POST_SAVE_CALL_SUCCESS',
  POST_SAVE_CALL_ERROR: 'VOIP/POST_SAVE_CALL_ERROR',

  SET_CALL_SID: 'VOIP/SET_CALL_SID',
  SET_CALL_ERROR: 'VOIP/SET_CALL_ERROR',
  SET_INTERRUPT_REQUEST: 'VOIP/SET_INTERRUPT_REQUEST',

  RESET: 'VOIP/RESET',
  RESET_CALL_ERROR: 'VOIP/RESET_CALL_ERROR',
};

//Action Creators
export const getVoipConnection = () => ({ type: Types.GET_VOIP_CONNECTION });
export const getVoipConnectionSuccess = (data) => ({ type: Types.GET_VOIP_CONNECTION_SUCCESS, data });
export const getVoipConnectionError = (error) => ({ type: Types.GET_VOIP_CONNECTION_ERROR, error });

export const getVoipRecordDetails = (id) => ({ type: Types.GET_VOIP_RECORD_DETAILS, id });
export const getVoipRecordDetailsSuccess = (data) => ({ type: Types.GET_VOIP_RECORD_DETAILS_SUCCESS, data });
export const getVoipRecordDetailsError = (error) => ({ type: Types.GET_VOIP_RECORD_DETAILS_ERROR, error });

export const postLigar = (data) => ({ type: Types.POST_LIGAR, data });
export const postLigarSuccess = (resp) => ({ type: Types.POST_LIGAR_SUCCESS, resp });
export const postLigarError = (error) => ({ type: Types.POST_LIGAR_ERROR, error });

export const postFinishActivity = (data) => ({ type: Types.POST_FINISH_ACTIVITY, data });
export const postFinishActivitySuccess = (resp) => ({ type: Types.POST_FINISH_ACTIVITY_SUCCESS, resp });
export const postFinishActivityError = (error) => ({ type: Types.POST_FINISH_ACTIVITY_ERROR, error });

export const postSaveCall = (data) => ({ type: Types.POST_SAVE_CALL, data });
export const postSaveCallSuccess = (resp) => ({ type: Types.POST_SAVE_CALL_SUCCESS, resp });
export const postSaveCallError = (error) => ({ type: Types.POST_SAVE_CALL_ERROR, error });

export const setCallSid = (callSid) => ({ type: Types.SET_CALL_SID, callSid });
export const setCallError = (error) => ({ type: Types.SET_CALL_ERROR, error });

export const setInterruptRequest = (interruptRequest) => ({ type: Types.SET_INTERRUPT_REQUEST, interruptRequest });

export const reset = () => ({ type: Types.RESET });
export const resetCallError = () => ({ type: Types.RESET_CALL_ERROR });

//saga
function* fetchGetVoipConnection(action) {
  try {
    const data = yield call(getVoipConnectionSdk);
    yield put(getVoipConnectionSuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(getVoipConnectionError(err));
  }
}

function* fetchGetVoipRecordDetails(action) {
  try {
    const data = yield call(getVoipRecordDetailsSdk, action.id);
    yield put(getVoipRecordDetailsSuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(getVoipRecordDetailsError(err));
  }
}

function* fetchPostLigar(action) {
  try {
    const resp = yield call(postLigarSdk, action.data);
    yield put(postLigarSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(postLigarError(err));
  }
}

function* fetchPostFinishActivity(action) {
  try {
    yield call(postFinishActivitySdk, action.data);
    yield put(postFinishActivitySuccess(action.data.finishActivity));
  } catch (err) {
    Notification.error(err.message);
    yield put(postFinishActivityError(err));
  }
}

function* fetchPostSaveCall(action) {
  try {
    const resp = yield call(postSaveCallSdk, action.data);
    yield put(postSaveCallSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(postSaveCallError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_VOIP_CONNECTION, fetchGetVoipConnection), 
  takeLatest(Types.GET_VOIP_RECORD_DETAILS, fetchGetVoipRecordDetails),
  takeLatest(Types.POST_LIGAR, fetchPostLigar),
  takeLatest(Types.POST_FINISH_ACTIVITY, fetchPostFinishActivity),
  takeLatest(Types.POST_SAVE_CALL, fetchPostSaveCall),
];

// Reducer
const initialState = Map({
  token: Map(),
  loadingVoipConnection: false,
  successVoipConnection: false,
  errorVoipConnection: false,

  voiceCallRecordId: null,

  recordDetails: Map(),
  loadingVoipRecordDetails: false,
  successVoipRecordDetails: false,
  errorVoipRecordDetails: false,

  callRecordingId: null,
  loadingPostLigar: false,
  successPostLigar: false,
  errorPostLigar: false,

  loadingPostSaveCall: false,
  successPostSaveCall: false,
  errorPostSaveCall: false,

  hasFinish: false,
  loadingPostFinishActivity: false,
  successPostFinishActivity: false,
  errorPostFinishActivity: false,

  callSid: null,
  errorCall: null,
  totalRequestForRecord: 0,
  interruptRequest: false,
});

const handleGetVoipConnection = (state, action) => {
  return state.set('loadingVoipConnection', true).set('successVoipConnection', false).set('errorVoipConnection', false);
};

const handleGetVoipConnectionSuccess = (state, action) => {
  const { data } = action;
  return state.set('token', data.token).set('voiceCallRecordId', data.voice_call_config_id).set('loadingVoipConnection', false).set('successVoipConnection', true).set('errorVoipConnection', false);
};

const handleGetVoipConnectionError = (state, action) => {
  return state.set('loadingVoipConnection', false).set('successVoipConnection', false).set('errorVoipConnection', action.error);
};

const handleGetVoipRecordDetails = (state, action) => {
  return state.set('loadingVoipRecordDetails', true).set('successVoipRecordDetails', false).set('errorVoipRecordDetails', false);
};

const handleGetVoipRecordDetailsSuccess = (state, action) => {
  const { data } = action;
  return state.set('recordDetails', data).set('totalRequestForRecord', state.get('totalRequestForRecord') + 1).set('loadingVoipRecordDetails', false).set('successVoipRecordDetails', true).set('errorVoipRecordDetails', false);
};

const handleGetVoipRecordDetailsError = (state, action) => {
  return state.set('loadingVoipRecordDetails', false).set('successVoipRecordDetails', false).set('errorVoipRecordDetails', action.error);
};

const handlePostLigar = (state, action) => {
  return state.set('loadingPostLigar', true).set('successPostLigar', false).set('errorPostLigar', false);
};

const handlePostLigarSuccess = (state, action) => {
  return state.set('loadingPostLigar', false).set('successPostLigar', true).set('errorPostLigar', false);
};

const handlePostLigarError = (state, action) => {
  return state.set('loadingPostLigar', false).set('successPostLigar', false).set('errorPostLigar', action.error);
};

const handleSetCallSid = (state, action) => {
  return state.set('callSid', action.callSid);
};

const handlePostFinishActivity = (state, action) => {
  return state.set('loadingPostFinishActivity', true).set('successPostFinishActivity', false).set('errorPostFinishActivity', false);
};

const handlePostFinishActivitySuccess = (state, action) => {
  return state.set('hasFinish', action.resp).set('loadingPostFinishActivity', false).set('successPostFinishActivity', true).set('errorPostFinishActivity', false);
};

const handlePostFinishActivityError = (state, action) => {
  return state.set('loadingPostFinishActivity', false).set('successPostFinishActivity', false).set('errorPostFinishActivity', action.error);
};

const handlePostSaveCall = (state, action) => {
  return state.set('loadingPostSaveCall', true).set('successPostSaveCall', false).set('errorPostSaveCall', false);
};

const handlePostSaveCallSuccess = (state, action) => {
  const { resp } = action;
  return state.set('callRecordingId', resp.VoiceCallRecordId).set('loadingPostSaveCall', false).set('successPostSaveCall', true).set('errorPostSaveCall', false);
};

const handlePostSaveCallError = (state, action) => {
  return state.set('loadingPostSaveCall', false).set('successPostSaveCall', false).set('errorPostSaveCall', action.error);
};

const handleSetCallError = (state, action) => {
  const { error } = action;
  const translatedError = translateErrors(error);
  if(error.code === 31005) {
    Notification.info(translatedError);
  } else {
    Notification.error(translatedError);
  }
  return state.set('errorCall', action.error);
};

const handleSetInterruptRequest = (state, action) => {
  return state.set('interruptRequest', action.interruptRequest);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_VOIP_CONNECTION:
      return handleGetVoipConnection(state, action);
    case Types.GET_VOIP_CONNECTION_SUCCESS:
      return handleGetVoipConnectionSuccess(state, action);
    case Types.GET_VOIP_CONNECTION_ERROR:
      return handleGetVoipConnectionError(state, action);

    case Types.GET_VOIP_RECORD_DETAILS:
      return handleGetVoipRecordDetails(state, action);
    case Types.GET_VOIP_RECORD_DETAILS_SUCCESS:
      return handleGetVoipRecordDetailsSuccess(state, action);
    case Types.GET_VOIP_RECORD_DETAILS_ERROR:
      return handleGetVoipRecordDetailsError(state, action);

    case Types.POST_LIGAR:
      return handlePostLigar(state, action);
    case Types.POST_LIGAR_SUCCESS:
      return handlePostLigarSuccess(state, action);
    case Types.POST_LIGAR_ERROR:
      return handlePostLigarError(state, action);

    case Types.POST_FINISH_ACTIVITY:
      return handlePostFinishActivity(state, action);
    case Types.POST_FINISH_ACTIVITY_SUCCESS:
      return handlePostFinishActivitySuccess(state, action);
    case Types.POST_FINISH_ACTIVITY_ERROR:
      return handlePostFinishActivityError(state, action);

    case Types.POST_SAVE_CALL:
      return handlePostSaveCall(state, action);
    case Types.POST_SAVE_CALL_SUCCESS:
      return handlePostSaveCallSuccess(state, action);
    case Types.POST_SAVE_CALL_ERROR:
      return handlePostSaveCallError(state, action);

    case Types.SET_CALL_SID:
      return handleSetCallSid(state, action);
    case Types.SET_CALL_ERROR:
      return handleSetCallError(state, action);
    case Types.SET_INTERRUPT_REQUEST:
      return handleSetInterruptRequest(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_CALL_ERROR:
      return state.set('errorCall', null);

    default:
      return state;
  }
}
