import React from 'react';
import { Tooltip, Tag } from '@components-teammove';
import { formatDateTime } from '@utils';
import { getLabel, getPrazo } from './rules';
import TooltipItem from './TooltipItem';
import { LateText, TextStyle } from './styles';

const STATUS = {
  NORMAL: 'NORMAL',
  ATRASADO: 'ATRASADO',
  ALERTA: 'ALERTA',
};

const PrazoStatusItem = ({ chamado }) => {
  const { prazoStatus } = chamado;

  if (!prazoStatus || prazoStatus.prazo < 1) {
    return <TextStyle font='18px' weight='400'>{formatDateTime(chamado.prazo)}</TextStyle>;
  } 

  switch(prazoStatus.status) {
    case STATUS.NORMAL:
      return ( 
        <Tooltip title={<TooltipItem descricao={'Vencimento: ' + formatDateTime(chamado.prazo)}/>}>
          <TextStyle font='12px' weight='400'>{getPrazo(prazoStatus.prazo)} {getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</TextStyle>          
        </Tooltip>
      );
    case STATUS.ATRASADO:      
      return (
        <Tooltip title={<TooltipItem titulo="Ticket atrasado" descricao={'Vencimento: ' + formatDateTime(chamado.prazo)} />}>
          <Tag $bgColor='#FF3B30'>
            <LateText>
              Atrasado {getPrazo(prazoStatus.prazo)} <strong>{getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</strong>
            </LateText>
          </Tag>          
        </Tooltip>
      );
    case STATUS.ALERTA:
      return (
        <Tooltip title={<TooltipItem descricao={'Vencimento: ' + formatDateTime(chamado.prazo)} />}>
          <TextStyle font='12px' weight='400'>{getPrazo(prazoStatus.prazo)} {getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</TextStyle>          
        </Tooltip>
      );        
    default: return null;   
  }

};

export default PrazoStatusItem;