import styled from 'styled-components';
import { Icon } from '@components-teammove';

export const VerticalDivider = styled.div`
  border-left: 2px solid ${({ color })=> color || '#D9D9D9' };
  height: 44px;
`;

export const ImportanceIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid ${({ theme, importance }) => importance === 'Relevante' ? 'var(--success-color)' : (importance === 'Nao_Relevante' || importance === 'Erro') ? 'var(--danger-color)'  : theme.darkSecondaryColor};
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme, importance }) => importance === 'Relevante' ? 'var(--success-color)' : (importance === 'Nao_Relevante' || importance === 'Erro') ? 'var(--danger-color)' : theme.textColor};

`;