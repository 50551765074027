import { Device } from '@twilio/voice-sdk';
import { Notification } from '@utils';
import { setCallSid, setCallError } from '@ducks/voip';
import { USER_STATE } from '../../containers/Voip/Types';

export const InitConnection = (token, setCallDevice, setUserState, setConnection, setIsConnectionReady) => {
  if (!token) return;

  try {
    const device = new Device(token, {
      logLevel: 1,
      edge: 'sao-paulo',
      codecPreferences: ['opus', 'pcmu'],
      maxAverageBitrate: 64000,
    });
    device.register();
    setCallDevice(device);
  
    device.on('registered', () => {
      setUserState(USER_STATE.READY);
      setIsConnectionReady(true);
    });
    device.on('connect', (connection) => {
      setConnection(connection);
      setUserState(USER_STATE.CALLING);
    });
    device.on('disconnect', () => {
      setUserState(USER_STATE.DISCONNECTED);
      setConnection(null);
    });
    device.on('error', (error) => {
      console.error('Device error:', error);
    });
    device.on('unregistered', () => {
      setUserState(USER_STATE.OFFLINE);
    });
  
    return () => {
      device.destroy();
      setCallDevice(null);
      setUserState(USER_STATE.OFFLINE);
    };
  } catch (error) {
    Notification.error(`Erro ao inicializar dispositivo: ${error}`);
  }
};

export const tryCalling = (callDevice, setConnection, setUserState, userState, params, setWarningText, dispatch) => {
  // eslint-disable-next-line no-unused-expressions
  callDevice?.emit('connect');
  callDevice.connect({
    params: params,
    rtcConstraints: {
      audio: true,
    },
  }).then((connection) => {
    connection.on('ringing', () => {
      setConnection(connection);
      setUserState(USER_STATE.RINGING);
    });
    connection.on('accept', (conn) => {
      dispatch(setCallSid(conn.parameters.CallSid));
      setConnection(connection);
      setUserState(USER_STATE.ON_CALL);
    });
    connection.on('disconnect', (conn) => {
      dispatch(setCallSid(conn.parameters.CallSid));
      setUserState(USER_STATE.DISCONNECTED);
      setConnection(null);
    });
    connection.on('reject', () => {
      setUserState(USER_STATE.REJECTED);
    });
    connection.on('cancel', () => {
      setUserState(USER_STATE.CANCELED);
    });
    connection.on('warning', (warningName, warningData) => {
      setWarningText('Conexão está instável');
    });
    connection.on('warning-cleared', () => {
      setWarningText(null);
    });
    connection.on('error', (error) => {
      console.error('Connection error:', error);
      dispatch(setCallError(error));
    });
  }).catch((error) => {
    console.error('Error making call:', error);
  });
};

export const translateErrors = (error) => {
  switch (error.code) {
    case 31000:
      return 'Erro ao conectar com o servidor de chamadas: Isso pode ser causado por um problema de rede ou configuração incorreta.';
    case 31001:
      return 'O cliente desconectou antes da conexão ser estabelecida. Por favor, tente novamente.';
    case 31002:
      return 'Erro de autenticacão: Credenciais inválidas. Verifique suas credenciais e tente novamente.';
    case 31003:
      return 'Erro de autorização: Você não tem permissão para fazer esta chamada. Verifique suas permissões e tente novamente.';
    case 31005:
      return 'Erro de conexão: Verifique se o número de telefone está correto. Se estiver, pode haver um problema com a operadora. Tente ligar novamente.';
    case 31008:
      return 'Tempo esgotado para a conexão: A chamada não pôde ser completada a tempo. Tente novamente mais tarde.';
    case 31009:
      return 'Número não existe ou está incorreto. Verifique o número e tente novamente.';
    default:
      return 'Erro desconhecido: Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde ou entre em contato com o suporte.';
  }
};