import React, { useEffect, useState } from 'react';
import { Column, Animations, Row, Dialer } from '@components-teammove';
import { AnimationContainer, AudioText, ButtonOptionsText, ContainerInteractionButton, CounterTimer, IconFeather, IconsInteraction, VolumeControllerContainer } from './styles';
import LoadingCalling from '@assets/calling.json';
import { USER_STATE } from '../../Types';

const Calling = ({ mediaStream, finishCall, userState, connection, warningText }) => {
  const [openDialPad, setOpenDialPad] = useState(false);
  const [microphoneMuted, setMicrophoneMuted] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [microphoneLevel, setMicrophoneLevel] = useState(0);

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (/^[0-9#*]$/.test(key)) {
        handleSendDigitsToPhone(key);
      }
    };
  
    window.addEventListener('keydown', handleKeyPress);
    if(connection) {
      connection.mute(false);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [connection]);

  useEffect(() => {
    let timer = null;
    if(userState === USER_STATE.ON_CALL) {
      timer = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [userState]);

  useEffect(() => {
    if (mediaStream) {
      const audioContext = new window.AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(mediaStream);
      source.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const calculateMicrophoneLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;
        setMicrophoneLevel(average);
      };

      const interval = setInterval(calculateMicrophoneLevel, 100);

      return () => {
        clearInterval(interval);
        audioContext.close();
      };
    }
  }, [mediaStream]);

  const handleMicrophone = () => {
    if (mediaStream) {
      const audioTrack = mediaStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setMicrophoneMuted(!audioTrack.enabled);
      connection.mute(!audioTrack.enabled);
    }
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleMicrophoneLevel = () => {
    if (microphoneLevel > 0 && microphoneLevel < 20) {
      return 'volume-low';
    } else if (microphoneLevel >= 20 && microphoneLevel < 40) {
      return 'volume-medium';
    } else if (microphoneLevel >= 40) {
      return 'volume-high';
    } else {
      return 'volume-off';
    }
  };

  const handleSendDigitsToPhone = (number) => {
    if(connection) {
      connection.sendDigits(number);
    }
  };

  return (
    <Column align='center' width='100%' gap='12px'>     
      {userState === USER_STATE.ON_CALL ? (
        <>
          <VolumeControllerContainer>
            <Row gap='12px' align='center'>
              <AudioText>Microfone</AudioText>
              <IconsInteraction levelMicrophoneVolume={handleMicrophoneLevel()} type={handleMicrophoneLevel()}/>
            </Row>
          </VolumeControllerContainer>
          <CounterTimer>{formatDuration(callDuration)}</CounterTimer>
          {warningText && 
            <Row gap='12px' align='center'>
              <IconFeather type='alert-triangle'/>
              <AudioText>{warningText}</AudioText>
            </Row>
          }
        </>
      ) : (
        <AnimationContainer>
          <Animations src={LoadingCalling} loop/>
        </AnimationContainer>
      )}
      {openDialPad && <Dialer onChange={handleSendDigitsToPhone}/>}
      <Row justify='center' gap='32px'>
        <Column align='center' gap='4px'>
          <ContainerInteractionButton color='red' onClick={finishCall}>
            <IconsInteraction type='phone-remove'/>
          </ContainerInteractionButton>
          <Column align='center'>
            <ButtonOptionsText>Finalizar</ButtonOptionsText>
            <ButtonOptionsText>Chamada</ButtonOptionsText>
          </Column>
        </Column>
        <Column align='center' gap='4px'>
          <ContainerInteractionButton onClick={() => setOpenDialPad(!openDialPad)}>
            <IconsInteraction type='dialpad'/>
          </ContainerInteractionButton>
          <Column align='center'>
            <ButtonOptionsText>{openDialPad ? 'Fechar' : 'Abrir'} teclado</ButtonOptionsText>
            <ButtonOptionsText>numérico</ButtonOptionsText>
          </Column>
        </Column>
        <Column align='center' gap='4px'>
          <ContainerInteractionButton microphoneMuted={microphoneMuted} onClick={handleMicrophone}>
            <IconsInteraction microphoneMuted={microphoneMuted} type={microphoneMuted ? 'microphone-off' : 'microphone'}/>
          </ContainerInteractionButton>
          <Column align='center'>
            <ButtonOptionsText>{microphoneMuted ? 'Desmutar' : 'Mutar'}</ButtonOptionsText>
            <ButtonOptionsText>microfone</ButtonOptionsText>
          </Column>
        </Column>
      </Row>
    </Column>
  );
};

export default Calling;