import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams , useHistory } from 'react-router-dom';

import App from '@app';

import { Icon, Breadcrumb, Modal, Button, StatusTag, Skeleton, Chat, Row } from '@components-teammove';

import { InfoUsuario, download, isImage, getIdTemp, TelasNomenclaturas } from '@utils';
import { ChatTitle, ChatTitleRow, Header, FillView, ProtocolText, StylePageHeader, StyleColumn, ContentMessage, StyleBody, InfoParentTag, DataIcons, AlertText, AlertStar } from './styles';

import { mapHistoricos, TIPO } from './rules';
import { STATUS_AGUARDANDO_RESPOSTA, STATUS } from '../rules';
import { ACTION, getActionTypeFromMessage } from './CustomView/Action/rules';

import { getChamado, reset, sendMessage, changeStatus, changeStatusLido, changeReadStatus, changeAguardandoResposta, deleteMessage, changeChamadoTitulo } from '@ducks/chamadosHistorico';
import MessageForm from './MessageForm';
import MessageCompleted from './MessageCompleted';
import ModalAvaliacao from './ModalAvaliacao';
import ModalAguardandoResposta from './ModalAguardandoResposta';
import MessageAnswerPending from './MessageAnswerPending';
import ModalQuestionarioVisualizacao from '../Questionario/Visualizacao';
import MenuItem from './MenuItem/Index';

import { getUsuarios } from '@sdk/Chamados';

export default function Ticket({ visible, onCancel, ...props }) {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const theme = useSelector(({ app }) => app.get('theme'));
  const chamado = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));
  const historicos = useSelector(({ chamadosHistorico }) => mapHistoricos(chamadosHistorico.get('historicos').toList().toArray(), chamado));
  const loading = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loading'));
  const success = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('success'));
  const loadingEnvio = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingEnvio'));
  const loadingStatus = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingStatus'));
  const loadingAguardandoResposta = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingAguardandoResposta'));
  const successAguardandoResposta = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successAguardandoResposta'));
  const loadingChamadoTitulo = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingChamadoTitulo'));
  const successChamadoTitulo = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successChamadoTitulo'));

  const usuario = InfoUsuario.get();
  const permiteAtendimento = InfoUsuario.perm('chamadosAtendimento', usuario);
  const permiteAdministrar = InfoUsuario.perm('chamadosAdministrador', usuario);
  const isAberto = chamado.status === STATUS.ABERTO;
  const isEmAtendimento = chamado.status === STATUS.EM_ATENDIMENTO;
  const isReaberto = chamado.status === STATUS.REABERTO;
  const isAguardandoAvaliacao = chamado.status === STATUS.AGUARDANDO_AVALIACAO;
  const isFechado = chamado.status === STATUS.FECHADO;
  const isUsuarioCriador = usuario.cdUsuario === chamado.idUsuarioCriacao;
  const isAguardandoPendencia = chamado.aguardandoResposta === true;
  const isUserReponsible = chamado?.idUsuarioAtendimento === usuario.cdUsuario;
  const [modalImgIndex, setModalImgIndex] = useState(false);

  const [visibleAvaliacao, setVisibleAvaliacao] = useState(false);
  const handleCloseAvaliacao = () => setVisibleAvaliacao(false);
  const handleOpenAvaliacao = () => setVisibleAvaliacao(true);

  const [visibleAguardandoResposta, setVisibleAguardandoResposta] = useState(false);
  const handleOpenAguardandoResposta = () => setVisibleAguardandoResposta(true);
  const handleCloseAguardandoResposta = () => setVisibleAguardandoResposta(false);

  const [visibleBriefing, setVisibleBriefing] = useState(false);
  const [printingBriefing, setPrintingBriefing] = useState(false);

  const [ collapsed, setCollapsed] = useState(false);

  const [possibleAssignees, setPossibleAssignees] = useState([]);
  const [loadingPossibleAssignees, setLoadingPossibleAssignees] = useState(false);

  const [editTitle, setEditTitle] = useState(false);
  const [chamadoTitle, setChamadoTitle] = useState(chamado?.assunto);
  const chamadoTitleIsEmpty = chamadoTitle?.trim() === '';

  const loggedUserIsPossibleAssignee = useMemo(() => {
    return possibleAssignees.some(({ key }) => key === usuario.cdUsuario);
  }, [possibleAssignees, usuario.cdUsuario]);
  
  const modalBodyBottom = useRef(null);

  useEffect(() => {
    if (id) {
      dispatch(getChamado(id));
      dispatch(changeStatusLido(InfoUsuario.get('cdUsuario'), id));
      dispatch(changeReadStatus(id));
    }

    return () => {
      dispatch(reset());
    };
  }, [id]);

  useEffect(() => {
    if (success) {
      setLoadingPossibleAssignees(true);
      getUsuarios(true, chamado.idCategoria, chamado.idUnidade).then((usuarios) => {
        setPossibleAssignees(usuarios);
        setLoadingPossibleAssignees(false);
      });

      if (modalBodyBottom && modalBodyBottom.current) {
        scrollToBottom();
      }
      setChamadoTitle(chamado?.assunto);
    }
  }, [success, successChamadoTitulo]);

  useEffect(() => {
    if ((loadingEnvio) && (visibleAvaliacao)) {
      handleCloseAvaliacao();
    }
  }, [loadingEnvio]);

  useEffect(() => {
    if (successAguardandoResposta) {
      handleCloseAguardandoResposta();
    }
  }, [successAguardandoResposta]);

  useEffect(() => {
    if (editTitle && titleRef.current) {
      titleRef.current.focus();
    }
  }, [editTitle]);

  const scrollToBottom = () => {
    modalBodyBottom.current.scrollIntoView();
  };

  const sendMessageParams = (tipo, mensagem) => {
    dispatch(sendMessage({
      idTemp: getIdTemp(),
      idUsuario: usuario.cdUsuario,
      idChamado: id,
      tipo,
      mensagem,
    }, usuario));
  };

  const handleAvaliacao = (avaliacao) => {
    sendMessageParams(TIPO.AVALIACAO, JSON.stringify(avaliacao));
  };

  const handleIniciarAtendimento = () => {
    dispatch(changeStatus(id, STATUS.EM_ATENDIMENTO));
  };

  const handleResolverAtendimento = () => {
    dispatch(changeStatus(id, STATUS.AGUARDANDO_AVALIACAO));
  };

  const handleReabrirAtendimento = (fechado) => {
  
    if (historicos && historicos.length > 0) {
      const hasAvaliation = historicos.find((historico) => historico?.text?.props?.avaliacao)?.text?.props?.historico;
        
      if (fechado && hasAvaliation) {
        const message = hasAvaliation;
        dispatch(deleteMessage(message));
      }
    }
    
    dispatch(changeStatus(id, STATUS.REABERTO));
  };

  const handleAguardandoResposta = ({ mensagem }) => {
    dispatch(changeAguardandoResposta(id, usuario.cdUsuario, !chamado.aguardandoResposta, mensagem));
  };

  const getLabelPendencia = () => {
    if (!chamado.aguardandoResposta) {
      return 'Gerar pendência';
    } else {
      return isUsuarioCriador ? 'Responder pendência' : 'Cancelar pendência';
    }
  };

  const historicoImages = historicos.filter((historico) => historico.active && historico.data && historico.data.uri && isImage(historico.data.uri));
  const handleViewParent = (id) => {
    window.open(`/chamados/tickets/${id}`);

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      handleChangeTitle();
    }
  };

  const handleChangeTitle = () => {
    if (chamadoTitleIsEmpty) return;
    dispatch(changeChamadoTitulo(id, chamadoTitle));
    setEditTitle(false);
  };

  const handleCancelChangeTitle = () => {
    setChamadoTitle(chamado.assunto);
    setEditTitle(false);
  };

  return(
    <App bgColor={theme['@global-background-color']} hideFooter>
      <StyleBody fixed>
        <StylePageHeader 
          marginlessHeader
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/chamados/tickets', name: `${ticketNomenclatures.plural}` },
                { path: '/chamados/tickets/:id', name: chamado.assunto || '...', current: true }
              ]}
            />
          )}
          onBack={() => {
            if(history.length > 1) {
              history.goBack();
            } else {
              history.push('/chamados/tickets');
            }}}
          title={(
            <Header>
              {loading ? (<Skeleton.Title/>) : (
                <>
                  <ChatTitleRow title={chamado.assunto}>
                    {loadingChamadoTitulo ? (
                      <Skeleton.Title/>
                    ) : (
                      <>
                        <ChatTitle 
                          disabled={!editTitle}
                          value={chamadoTitle}
                          onChange={(e) => setChamadoTitle(e.target.value)}
                          maxLength={60}
                          onKeyDown={(e) => handleKeyDown(e)}
                          ref={titleRef}                   
                        />
                      </>
                    )}
                    {!editTitle && (
                      <>
                        {chamado.aguardandoResposta ? usuario.cdUsuario === chamado.idUsuario ? chamado.aguardandoRespostaStatus === STATUS.ATRASADO ? (
                          <StatusTag color='#F44336' text='Pendência atrasada' size='large'/>
                        ) : (
                          <StatusTag color='#E65100' text='Pendência aberta' size='large'/>
                        ) : (
                          <StatusTag color='#E65100' text='Aguardando pendência' size='large'/>
                        ) : (
                          <StatusTag 
                            color={chamado.statusCor} 
                            text={chamado.statusDs} 
                            size='large'
                          />
                        )}
                        <DataIcons
                          onClick={() => setEditTitle(true)}
                          $opacity
                          size='20'
                          type='edit'
                        />
                      </>
                    )}
                  </ChatTitleRow>
                  {editTitle && chamadoTitleIsEmpty && (
                    <Row gap='4px'>
                      <AlertStar>*</AlertStar>
                      <AlertText>O título não pode estar vazio!</AlertText>
                    </Row>
                  )}
                  <Row gap='8px' align='center'>                 
                    <ProtocolText>{chamado.protocolo}</ProtocolText>
                    {chamado.idChamadoPai && (
                      <InfoParentTag onClick={()=> handleViewParent(chamado.idChamadoPai)}>Etapa do chamado: {chamado?.nmChamadoPai}</InfoParentTag>
                    )}
                  </Row>
                </>
              )}
            </Header>
          )}
          darkenedSubTitle={false}
          extra={loading ? [<Skeleton.ExtraButton key='loading'/>] : [
            <Button key='btn-1' size='small' context='header' type={chamado.aguardandoResposta && isUsuarioCriador ? 'warning' : 'default'} backgroundColor={chamado.aguardandoResposta && isUsuarioCriador ? '#e65100' : null} icon={chamado.aguardandoResposta && isUsuarioCriador ? <Icon.WarningOutlined /> : null} onClick={handleOpenAguardandoResposta} visible={(!chamado.aguardandoResposta && isUsuarioCriador && !isUserReponsible) ? false : (chamado.aguardandoResposta || isEmAtendimento || isReaberto) && !editTitle}>{getLabelPendencia()}</Button>,
            <Button key='btn-2' size='small' context='header' type="primary" onClick={handleOpenAvaliacao} loading={loadingStatus || loadingEnvio} visible={isAguardandoAvaliacao && isUsuarioCriador && ((!chamado.aguardandoResposta) || (chamado.aguardandoResposta && chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO)) && !editTitle}>Avaliar Atendimento</Button>,
            <Button key='btn-3' size='small' context='header' type="warning" icon={<Icon.ReloadOutlined />} onClick={() => handleReabrirAtendimento(false)} loading={loadingStatus} visible={isAguardandoAvaliacao && chamado.permiteReabrirComStatusAguardandoAvaliacao && ((!chamado.aguardandoResposta) || (chamado.aguardandoResposta && chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO)) && !editTitle}>Reabrir Atendimento</Button>,
            <Button
              key='btn-4' 
              size='small' 
              context='header' 
              type="success" 
              icon={<Icon.Font type="play" />} 
              onClick={handleIniciarAtendimento} 
              loading={loadingStatus || loadingPossibleAssignees} 
              visible={
                permiteAtendimento && isAberto && ((!chamado.aguardandoResposta) || (chamado.aguardandoResposta && chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO)) && loggedUserIsPossibleAssignee && !editTitle
              }
            >
                Iniciar Atendimento</Button>,
            <Button
              key='btn-5' size='small' context='header' type="success" icon={<Icon.CheckOutlined />} onClick={handleResolverAtendimento} visible={permiteAtendimento && (isEmAtendimento || isReaberto) && !editTitle} loading={loadingStatus}
              disabled={chamado.aguardandoResposta}
            >Marcar como Resolvido</Button>,
            <Button key='btn-6' size='small' context='header' type="warning" icon={<Icon.ReloadOutlined />} onClick={() => handleReabrirAtendimento(true)} loading={loadingStatus} visible={isFechado && permiteAdministrar && !editTitle}>Reabrir Atendimento</Button>,
            <Button key='btn-7' size='small' context='header' type="secondary" onClick={() => handleCancelChangeTitle()} visible={permiteAdministrar && editTitle}>Cancelar</Button>,
            <Button key='btn-8' size='small' context='header' type="secondary" onClick={() => handleChangeTitle()} loading={loadingStatus} visible={permiteAdministrar && editTitle}>Salvar</Button>,
          ]}
          stickyHeader
          subHeader={(
            <FillView bordered />  
          )}
        >
          
          <StyleColumn collapsed={collapsed}>
            {loading || loadingChamadoTitulo ? <Skeleton.Chat/> : (
              <Chat
                lockable
                toBottomHeight="100%"
                dataSource={historicos}
                onOpen={(historico) => {
                  if ((historico.data) && (historico.data.uri)) {
                    setModalImgIndex(historicoImages.findIndex((hist) => hist.data?.uri === historico.data.uri));
                  }
                }}
                onDownload={(historico) => {
                  download(historico.data.uri);
                }}
              />)}
          
            {(isAguardandoPendencia && isUsuarioCriador) && (
              <MessageAnswerPending handleOpenAguardandoResposta={handleOpenAguardandoResposta}/>
            )}
            <div ref={modalBodyBottom}/>
          </StyleColumn>

        </StylePageHeader>
        {((isAberto && isUsuarioCriador) || (isAguardandoPendencia && !isUsuarioCriador) || (!isAguardandoPendencia && ((isEmAtendimento) || (isReaberto) || (isAguardandoAvaliacao && !isUsuarioCriador)))) && (
          <ContentMessage collapsed={collapsed}>
            <MessageForm idChamado={id} scrollToBottom={scrollToBottom}/>
          </ContentMessage>
        )}
        {(isAguardandoAvaliacao && isUsuarioCriador) || isFechado ? (
          <MessageCompleted 
            collapsed={collapsed}
            isClosed={isFechado}
            rate={isFechado && chamado.avaliacao}
            handleOpenAvaliacao={handleOpenAvaliacao}
            handleReabrirAtendimento={handleReabrirAtendimento}
            endedTicketAt={historicos.reduce((state, { text }) => (text?.props?.historic?.mensagem && getActionTypeFromMessage(text?.props?.historic?.mensagem) === ACTION.CHANGE_STATUS_TO_AWAITING_EVALUATION) ? text.props.historic.dtHistorico : state, '')}
          />
        ) : null}
        
      </StyleBody>

      {historicoImages[modalImgIndex] && (!!modalImgIndex || modalImgIndex === 0) && (
        <Modal.Picture
          onCancel={() => setModalImgIndex(false)}
          closable={false}
          pictures={historicoImages.map((img) => (img?.data?.uri)).filter((img) => img)}
          initialImageIndex={historicoImages.map((img) => img?.data?.uri).filter((img) => img).findIndex((img) => img === historicoImages[modalImgIndex].data?.uri)}
        />
      )}

      <ModalAvaliacao
        visible={visibleAvaliacao}
        onCancel={handleCloseAvaliacao}
        onAvaliar={handleAvaliacao}
        loading={loadingEnvio}
      />

      {visibleAguardandoResposta && (
        <ModalAguardandoResposta
          visible={visibleAguardandoResposta}
          onCancel={handleCloseAguardandoResposta}
          onOk={handleAguardandoResposta}
          loading={loadingAguardandoResposta}
        />
      )}

      {visibleBriefing && (
        <ModalQuestionarioVisualizacao
          visible={visibleBriefing}
          onCancel={() => setVisibleBriefing(false)}
          tipo={'BRIEFING'}
          printingBriefing={printingBriefing}
          setPrintingBriefing={setPrintingBriefing}
        />
      )}
      <MenuItem possibleAssignees={possibleAssignees} data={chamado} historicos={historicos} collapsed={collapsed} setCollapsed={setCollapsed}/>
    </App>
  );
}