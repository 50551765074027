import React from 'react';
import { ansiToDate, InfoUsuario, moment, getIdTemp, formatDate, formatTime, isImage, isAudio, getDownloadUrlAvatar } from '@utils';
import { Icon } from '@components-teammove';
import isImageUrl from 'is-image-url';
import { Action } from './CustomView';
import { Row } from './styles';
import Message from './Message';
import { ACTION, getActionTypeFromMessage } from './CustomView/Action/rules';

export const getLabelUploads = (uploads) => {
  return uploads.length > 1 ? (uploads.length + ' anexos não enviados') : (uploads.length + ' anexo não enviado');
};

const getUser = (historico, showUser) => (showUser) ? ({
  avatar: getDownloadUrlAvatar(historico.fotoUsuario),
  title: historico.nmUsuario,
}) : {};

const getMessage = (historico, showUser, sameUser) => {
  const message = {
    idTemp: historico.idTemp,
    position: (sameUser) ? 'right' : 'left',
    date: ansiToDate(historico.dtHistorico),
    dateString: formatTime(historico.dtHistorico),
    idUsuario: (historico.tipo !== TIPO.HISTORICO) ? historico.idUsuario : 0,
    notch: showUser,
    status: (sameUser) ? historico.status || 'sent' : '',
    active: historico.ativo,
    ...getUser(historico, showUser),
  };

  let messageTextFormatted = '';
  
  if (historico.tipo === TIPO.AVALIACAO) {
    const avaliacao = JSON.parse(historico.mensagem);
    message.text = <Message historico={historico} sameUser={sameUser} message={message} type={TIPO.AVALIACAO} avaliacao={avaliacao} messageTextFormatted={messageTextFormatted} noButton/>;
  } else if (historico.tipo === TIPO.HISTORICO) {
    message.type = 'system';
    message.text = (
      <Action historic={historico} />
    );
  } 
  
  if (historico.tipo === TIPO.ANEXO) {
    message.data = {
      uri: historico.mensagem,
    };

    if (isImageUrl(historico.mensagem) && isImage(historico.mensagem)) {
      message.type = 'photo';
      message.data.width = 'auto';
      message.data.height = 200;
      message.title = sameUser && historico.ativo ? (
        <Row $marginLeft align='center'>
          {message.title}
          <Message historico={historico} message={message} buttonOnly/>
        </Row>
      ) : message.title;
    } else if (isAudio(historico.mensagem)) {
      message.type = 'audio';
      message.data.audioURL = historico.mensagem;
      message.data.controlsList = 'noplaybackrate';
      message.title = sameUser && historico.ativo ? (
        <Row $marginLeft align='center'>
          {message.title}
          <Message historico={historico} message={message} buttonOnly/>
        </Row>
      ) : message.title;
    } else {
      message.type = 'file';
      messageTextFormatted = historico.mensagem.substring(historico.mensagem.lastIndexOf('/') + 1);
      message.data.status = {};
      message.title = sameUser && historico.ativo ? (
        <Row $marginLeft align='center'>
          {message.title}
          <Message historico={historico} message={message} buttonOnly/>
        </Row>
      ) : message.title;
      message.text = <Message historico={historico} sameUser={sameUser} message={message} messageTextFormatted={messageTextFormatted} noButton/>;
    }
  } 

  if (historico.tipo !== TIPO.AVALIACAO && historico.tipo !== TIPO.HISTORICO && historico.tipo !== TIPO.ANEXO) {
    if (sameUser && message.title) {
      message.title = historico.ativo ? (
        <Row $marginLeft align='center'>
          {message.title}
        </Row>
      ) : message.title;
    } 
    message.text = sameUser ? (
      <Message historico={historico} sameUser={sameUser} message={message} type={historico.tipo} messageTextFormatted={messageTextFormatted}/>
    ) : message.type !== 'photo' && message.type !== 'audio' && (
      <Message historico={historico} sameUser={sameUser} message={message} messageTextFormatted={messageTextFormatted} noButton/>
    );  
    
    if (!message.type && historico.tipo !== TIPO.AVALIACAO) {
      message.type = 'text';
    }
  }

  if (!historico.ativo && historico.status !== 'waiting') {
    message.type = 'text';
    message.text = <Message historico={historico} sameUser={sameUser} message={message} active={false} messageTextFormatted={messageTextFormatted}/>;
  }

  return message;
};

export const mapHistoricos = (dados, ticket) => {
  const historicos = [];
  const infoUsuario = InfoUsuario.get();
  let lastUser = 0;
  let lastDate = null;

  for (const historico of dados) {
    const showUser = lastUser !== historico.idUsuario;
    const isMessageUserLogged = historico.idUsuario === infoUsuario.cdUsuario;
    const isLoggedUserUnrelated = infoUsuario.cdUsuario !== ticket.idUsuarioAtendimento && infoUsuario.cdUsuario !== ticket.idUsuarioCriacao;
    const isLoggedUserResponsible = infoUsuario.cdUsuario === ticket.idUsuarioAtendimento;
    const isMessageUserUnrelated = historico.idUsuario !== ticket.idUsuarioAtendimento && historico.idUsuario !== ticket.idUsuarioCriacao;
    const isMessageUserResponsible = historico.idUsuario === ticket.idUsuarioAtendimento;
    const alignMessageToRight = isMessageUserLogged || (isLoggedUserUnrelated && isMessageUserResponsible) || (isMessageUserUnrelated && isLoggedUserResponsible);
    const message = getMessage(historico, showUser, alignMessageToRight);

    if (!moment(lastDate).isSame(message.date, 'day')) {
      historicos.push({
        idTemp: getIdTemp(),
        type: 'system',
        text: formatDate(message.date),
        className: 'date-msg',
      });
      lastDate = message.date;
    } 
    lastUser = message.idUsuario;
    historicos.push(message);
  }

  return historicos;
};

export const getActions = (historicos, { isAguardandoBriefing, isAguardandoPendencia, isAguardandoAvaliacao }) => {
  const historicFiltered = historicos.filter(({ text }) => text?.props?.historic);
  
  const historicFormatted = historicFiltered.map(({ text }, index) => {
    const ACTION_FROM_MESSAGE = getActionTypeFromMessage(text.props.historic.mensagem);
  
    switch (ACTION_FROM_MESSAGE) {
      case ACTION.CHANGE_STATUS_TO_OPEN:
        return {
          label: 'Ticket aberto',
          icon: <Icon.Feather type='fi-send'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.CHANGE_STATUS_TO_IN_ATTENDANCE:
        return {
          label: 'Atendimento iniciado',
          icon: <Icon.Feather type='fi-play-circle'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.CHANGE_STATUS_TO_REOPENED:
        return {
          label: 'Ticket reaberto',
          icon: <Icon.Feather type='fi-refresh-cw'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.CHANGE_STATUS_TO_CLOSED:
        return {
          label: 'Atendimento fechado',
          icon: <Icon.Feather type='fi-thumbs-up'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.RESPONSE_WAITING_FOR_ANSWER:
        return historicFiltered.slice(index + 1).some(({ text }) => getActionTypeFromMessage(text.props.historic.mensagem) === ACTION.RESPONSE_WAITING_FOR_ANSWER) ? null : {
          label: 'Pendência resolvida',
          icon: <Icon.MDI type='alert-circle-check-outline'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.RESPONSE_BRIEFING:
        return historicFiltered.slice(index + 1).some(({ text }) => getActionTypeFromMessage(text.props.historic.mensagem) === ACTION.RESPONSE_BRIEFING) ? null : {
          label: 'Briefing respondido',
          icon: <Icon.MDI type='beaker-check-outline'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.CHANGE_STATUS_TO_CANCEL:
        return {
          label: 'Ticket cancelado',
          icon: <Icon.Feather type='x-circle'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      case ACTION.CHANGE_TITLE:
        return {
          label: 'Titulo do ticket alterado',
          icon: <Icon.Feather type='x-circle'/>,
          date: text.props.historic.dtHistorico,
          nmUsuario: text.props.historic.nmUsuario
        };
      default: return null;
    }
  }).filter((action) => action);

  return isAguardandoBriefing ? [...historicFormatted.filter(({ label }) => label !== 'Briefing respondido'), { label: 'Aguardando briefing', icon: <Icon.MDI type='alert-outline'/>, state: 'warning' }] : 
    isAguardandoPendencia ? [...historicFormatted.filter(({ label }) => label !== 'Pendência resolvida'), { label: 'Aguardando pendência', icon: <Icon.MDI type='alert-outline'/>, state: 'warning' }] :
      isAguardandoAvaliacao ? [...historicFormatted, { label: 'Aguardando avaliação', icon: <Icon.MDI type='alert-outline'/>, state: 'warning' }] :
        [...historicFormatted];
};

export const TIPO = {
  CRIACAO: 'CRIACAO',
  MENSAGEM: 'MENSAGEM',
  HISTORICO: 'HISTORICO',
  ANEXO: 'ANEXO',
  AVALIACAO: 'AVALIACAO',
};
