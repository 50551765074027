import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from 'antd';
import { Icon } from '@components-teammove';
import { useSelector } from 'react-redux';

const getSelectedKeys = (menuItems, location) => {
  return menuItems
    .filter((item) => item.match && location.pathname.match(item.match))
    .map((item) => item.path);
};

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
`;

const Item = styled.div`
  user-select: none;

  &.ant-menu-item-selected {
    background-color: ${({ theme }) => theme.backgroundColor} !important;
    font-weight: bold !important;
  }

  &.ant-menu-item {
    :hover {
      background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    }
  }
`;

const Text = styled.span`
  font-size: 1rem !important;
`;

const MenuIcon = styled.div`
  font-size: 1.1rem !important;
  width: 10px;
`;

const DotIcon = styled(Icon.MDI)`
  position: absolute;
  left: -.5px;
  transform: scale(1.5);
`;

const AlignCenterLink = styled(Link)`
  display: flex !important;
  align-items: center;
`;

const MenuItem = ({ path, icon, text, sider, component, exact, secret, permissions, infoUsuario, allowed, ...otherProps }) => {
  return (
    <Item as={Menu.Item} {...otherProps}>
      <AlignCenterLink to={path}>
        {window.location.pathname === path && (
          <DotIcon type='circle-small'/>
        )}
        {(icon) && (otherProps.level === 1) && <MenuIcon as={icon} />}
        <Text>{text}</Text>
      </AlignCenterLink>
    </Item>
  );
};

const Title = ({ icon, text }) => (
  <Item>
    <MenuIcon as={icon} />
    <Text>{text}</Text>
  </Item>
);

const SubMenu = ({ icon, text, sider, childs, component, exact, secret, permissions, infoUsuario, allowed, ...otherProps }) => {
  return childs.some((item) => item.allowed) ? (
    <Item as={Menu.SubMenu} title={(<Title icon={icon} text={text} /> )} {...otherProps}>
      {childs.map((child) => (
        <MenuItem key={child.path} {...child} />
      ))}
    </Item>
  ) : null;
};

const MenuComponent = ({ infoUsuario, collapsed, routes }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  const location = useLocation();
  const selectedKeys = getSelectedKeys(routes, location);

  const menuItems = routes.filter((item) => item.sider);
  const siderRoutes = menuItems.filter((item) => !item.parent).map((item) => ({
    ...item,
    childs: menuItems.filter((route) => route.parent === item.path),
  })).filter((item) => item.component || item.childs.length);

  return (
    <StyledMenu mode="inline" selectedKeys={selectedKeys} defaultOpenKeys={collapsed ? [] : selectedKeys} theme="dark" $bgColor={theme['@global-dark-secondary-color'] || theme['@layout-header-background']}>
      {siderRoutes.map((menu) => (menu.childs.length > 0) ? (
        <SubMenu key={menu.path} {...menu} infoUsuario={infoUsuario} />
      ) : (
        <MenuItem key={menu.path} {...menu} infoUsuario={infoUsuario} />
      ))}
    </StyledMenu>
  );
};

export default MenuComponent;
