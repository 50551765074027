import React, { useState , useEffect } from 'react';
import { AvatarImg, Category, Column, Container, InfoWithIcon, Protocol, Row, RowWithRelativeGap, Subject, TagInnerText, Ticket, TicketBody, UserName } from './styles';
import { Icon, Card, StatusTag, Tag } from '@components-teammove';

import { InfoUsuario, getDownloadUrlAvatar } from '@utils';

import { getDashboard } from '@sdk/Chamados';
import { fakeData } from './fake';

export default function Kanban({ ...props }) {
  const [dashboard, setDashboard] = useState(fakeData);

  useEffect(() => {
    (async () => {
      const dashboard = await getDashboard(InfoUsuario.get('cdUsuario'));
      setDashboard(dashboard);
    })();
  }, []);

  return(
    <Container>
      {dashboard.map((status) => (
        <Card 
          title='Novos Tickets' 
          subtitle={(
            <Tag $bgColor='#40A33F'><TagInnerText>X Tickets novos</TagInnerText></Tag>
          )} 
          key={status.status}
        >
          {status.chamados.map((chamado) => (
            <Ticket key={chamado.id}>
              <AvatarImg src={getDownloadUrlAvatar(chamado.avatarUsuarioAtendimento)}/>
              <TicketBody>
                <RowWithRelativeGap>
                  <UserName>{chamado.nmUsuario}</UserName>
                  <InfoWithIcon>
                    <Icon.MDI type='home-variant-outline'/>
                    {chamado.nmUnidade}
                  </InfoWithIcon>
                </RowWithRelativeGap>
                <Subject>
                  {chamado.assunto}
                </Subject>
                <Column>
                  <Protocol>
                    {chamado.protocolo}
                  </Protocol>
                  <Category>
                    {chamado.nmCategoriaCompleto}
                  </Category>
                </Column>
                <Row>
                  <StatusTag color={chamado.statusCor} text={chamado.statusDs}/>
                  <InfoWithIcon>
                    <Icon.ClockCircleOutlined/>
                    {chamado.prazo}
                  </InfoWithIcon>
                </Row>
              </TicketBody>
            </Ticket>
          ))}
        </Card>
      ))}
    </Container>
  );
}