import React from 'react';
import { Tooltip, Tag } from '@components-teammove';
import { formatDateTime } from '@utils';
import { getLabel, getPrazo } from './rules';
import TooltipItem from './TooltipItem';
import { LateText, TextPrazo } from './styles';

const STATUS = {
  NORMAL: 'NORMAL',
  ATRASADO: 'ATRASADO',
  ALERTA: 'ALERTA',
};

const PrazoStatusItem = ({ chamado, cancelado }) => {
  const { prazoStatus } = chamado;

  if (!prazoStatus || prazoStatus.prazo < 1) {
    return <TextPrazo cancelado={cancelado}>{formatDateTime(chamado.prazo)}</TextPrazo>;
  } 

  switch(prazoStatus.status) {
    case STATUS.NORMAL:
      return ( 
        <Tooltip title={<TooltipItem descricao={'Vencimento: ' + formatDateTime(chamado.prazo)}/>}>
          <TextPrazo cancelado={cancelado}>{getPrazo(prazoStatus.prazo)} {getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</TextPrazo>          
        </Tooltip>
      );
    case STATUS.ATRASADO:      
      return (
        <Tooltip title={<TooltipItem titulo="Ticket atrasado" descricao={'Vencimento: ' + formatDateTime(chamado.prazo)} />}>
          <Tag $bgColor='#FF3B30'>
            <LateText cancelado={cancelado}>
              Atrasado {getPrazo(prazoStatus.prazo)} <strong>{getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</strong>
            </LateText>
          </Tag>          
        </Tooltip>
      );
    case STATUS.ALERTA:
      return (
        <Tooltip title={<TooltipItem descricao={'Vencimento: ' + formatDateTime(chamado.prazo)} />}>
          <TextPrazo cancelado={cancelado}>{getPrazo(prazoStatus.prazo)} {getLabel(prazoStatus.tipo, getPrazo(prazoStatus.prazo))}</TextPrazo>          
        </Tooltip>
      );        
    default: return null;   
  }

};

export default PrazoStatusItem;