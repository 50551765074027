import React from 'react';
import { BreadcrumbRow, Container } from './styles';

const StyledBreadcrumb = ({ items }) => (
  <BreadcrumbRow>
    {items.map((item, index) => (
      <Container key={item.path}>
        <a  key={item.path} href={item.path} style={item.current && { pointerEvents: 'none' }}>{item.name}</a>
        {index !== items.length - 1 && <span>&gt;</span>}
      </Container>
    ))}
  </BreadcrumbRow>
);

export default StyledBreadcrumb;