import React, { useState } from 'react';
import { Linkify, Text  } from '@components';
import { Icon , Modal, Dropdown, Menu, Rate } from '@components-teammove';

import { deleteMessage } from '@ducks/chamadosHistorico';
import { DeletedMessageWrapper, MarginBottom, MessageContainer, MessageOptionsButton, MessageText, Row } from './styles';
import { useDispatch } from 'react-redux';
import { TIPO } from '../rules';

export default function Message({ historico, type, active = true, avaliacao, message, sameUser, messageTextFormatted, buttonOnly, noButton }) {
  const dispatch = useDispatch();
  
  const handleDelete = () => dispatch(deleteMessage(historico));

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
      
  const handleMenuClick = (evt) => {
    if (evt.key === 'delete') {
      showConfirm();
    } else {
      copyText();
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(messageTextFormatted || historico.mensagem);
  };
  
  const showConfirm = () => {
    setConfirmDeleteVisible(true);
  };
  
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='delete'>
        <Icon.DeleteOutlined/>
        Apagar mensagem
      </Menu.Item>
      {(type === TIPO.MENSAGEM || type === TIPO.CRIACAO) && (
        <Menu.Item key='copy'>
          <Icon.CopyOutlined/>
          Copiar mensagem
        </Menu.Item>
      )}
    </Menu>
  );
  
  return(
    <>
      <MessageContainer>
        {!noButton && active && type !== TIPO.MENSAGEM && type !== TIPO.CRIACAO && (
          <Dropdown overlay={menu} placement='topRight' trigger={['click']}>
            <MessageOptionsButton type='ghost' icon={<Icon.EllipsisOutlined/>}/>
          </Dropdown>)
        }
        {!buttonOnly && (!active ? (
          <DeletedMessageWrapper>
            <Icon.StopOutlined/>
            <Text>Mensagem apagada</Text>
          </DeletedMessageWrapper>
        ) : type === TIPO.AVALIACAO ? (
          (
            <MarginBottom>
              <Rate value={avaliacao.nota} disabled />
              {avaliacao.comentario && <Text>{avaliacao.comentario}</Text>}
            </MarginBottom>
          )
        ) : type !== TIPO.AVALIACAO && type !== TIPO.HISTORICO ? (
          <Row gap='15px'>
            {message.type !== 'photo' && message.type !== 'audio' && (
            
              <MessageText $hasTitle={!!message.title}>
                <Linkify linkColor={sameUser ? '#ffffff' : '#424242'}>{messageTextFormatted || historico.mensagem}</Linkify>
              </MessageText>
            
            )}
            {!noButton && sameUser && (
              <Dropdown overlay={menu} placement='topRight' trigger={['click']}>
                <MessageOptionsButton type='ghost' icon={<Icon.EllipsisOutlined/>} $absolute={type === TIPO.MENSAGEM || type === TIPO.CRIACAO} $isText={type === TIPO.MENSAGEM || type === TIPO.CRIACAO}/>
              </Dropdown>)
            }
          </Row>
        ) : '')}
      </MessageContainer>
      <Modal
        visible={confirmDeleteVisible}
        title='Deseja realmente excluir esta mensagem?'
        okText='Ok'
        icon={<Icon.ExclamationCircleOutlined />}
        onOk={() => {
          handleDelete();
          setConfirmDeleteVisible(false);
        }}
        onCancel={() => setConfirmDeleteVisible(false)}
      />
    </>
  );
}