import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { Image }from '@components';

const FooterLayout = styled(Layout.Footer)`
  text-align: center;
  flex: 0 0 auto;
  background-color: ${({ $bgColor }) => $bgColor || '#fff'} !important;
`;

const ImageFooter = styled(Image)`
  width: 130px;
  user-select: none;
`;

const Footer = ({ bgColor }) => (
  <FooterLayout $bgColor={bgColor}>
    <ImageFooter alt="TeamMove" src={process.env.PUBLIC_URL + '/images/footer.png'} />
  </FooterLayout>
);

export default Footer;
