import React from 'react';
import { Tag } from '@components-teammove';
import { TextClassificacao } from './styles';

const ClassificacoesTag = ({ chamado }) => {  
  return(
    chamado.classificacoes.length > 0 && chamado.classificacoes.map((classificacao, index) => (
      classificacao.nomeOpcaoEscolhida && (
        <Tag key={classificacao.id} $bgColor={classificacao.corOpcaoEscolhida}>
          <TextClassificacao>
            {classificacao.nomeOpcaoEscolhida}
          </TextClassificacao>
        </Tag>    
      )
    )
    )
  );
};

export default ClassificacoesTag;